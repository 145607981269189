import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import Select from 'react-select'
import Banner from '../../components/Banner'
import BreadCrumbBlock from '../../components/Breadcrumb'
import FeaturedProductItem from '../../components/FeaturedProduct/FeaturedProductItem'
import Layout from '../../components/Layout'
import Section from '../../components/Layout/Section'
import Title from '../../components/Layout/Title'
import Tile from '../../components/Tile'
import {graphql, StaticQuery, useStaticQuery} from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentCategoryId } from '../../store/registry'
import Cate from '../../components/Categories/Categories'
import Filter from '../../components/Categories/Filter'
import Product from '../../models/product'
import { Helmet } from 'react-helmet'

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: base => ({
    ...base,
    width: 'max-content',
    zIndex: 10,
  }),
  container: provided => ({
    ...provided,
    minWidth: 125,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'rgba(0, 0, 0, 0.5)'
      : isSelected
      ? 'rgba(0, 0, 0, 1)'
      : 'rgba(0, 0, 0, 0)',
    color: isFocused ? '#ffffff' : isSelected ? '#ffffff' : undefined,
  }),
}

const Categories = ({ pageContext: { category, products, categories } }) => {

  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const [visible, setVisible] = useState(36)

  const dispatch = useDispatch()
  const defaultFilters = {
    categories: [],
    childSectors: [],
    brands: [],
    ranges: [],
    rooms: [],
    ages: [],
    excVisibility: ['Not Visible Individually'],
    limit: 'all',
    sort: 'az',
    minPrice: '',
    maxPrice: '',
    color: {
      label: '',
      value: undefined,
      colorCode: undefined
    },
    swatches: []
  }
  const [filterValues, setFilterValues] = useState({ ...defaultFilters })
  const [currentPage, setCurrentPage] = useState(1)

  const [items, setItems] = useState([])

  const onPaginationItemClick = useCallback(p => {
    setCurrentPage(p)
  }, [])

  useEffect(() => {
    fetch('/color_values.json')
      .then(res => res.json())
      .then(allColors => {
        setFilterValues(oldValues => {
          const colorsInCategory = new Set()
          let categoryItems = products.map(p => new Product(p, priceRuleItems))
          if (filterValues.excVisibility.length) {
            categoryItems = categoryItems.filter(p => {
              if (!p.visibility_text) return false
              return !filterValues.excVisibility.includes(p.visibility_text)
            })

            for (const item of categoryItems) {
              if (item.isBundle) continue
              if (item.type === Product.SIMPLE) {
                colorsInCategory.add(item.colour)
              }
              if (item.type === Product.CONFIGURABLE) {
                for (const v of item.variants) {
                  colorsInCategory.add(v.colour)
                }
              }
            }
          }
          oldValues['swatches'] = allColors.filter(c => colorsInCategory.has(c.value))
          return { ...oldValues }
        })
      })
  }, [priceRuleItems])

  useEffect(() => {
    dispatch(setCurrentCategoryId(category.id))
  }, [dispatch, category.id])

  useEffect(() => {
    if(typeof window !== 'undefined'){
      const params = new URLSearchParams(window.location.search)
      setCurrentPage(parseInt(params.get('page') || 1, 10))
    }
  }, [])

  useEffect(() => {
    if (!category) return
    const startIndex = (currentPage - 1) * filterValues.limit
    const endIndex = currentPage * filterValues.limit
    const sale_categories_name = [
      'Clearance',
      'Hot Buys',
      'Sale',
      'Sofa Specials',
    ]
    let categoryItems = products.map(p => new Product(p, priceRuleItems))

    // filter categories
    if (filterValues.categories.length) {
      categoryItems = categoryItems.filter(p =>
        p.categories.some(pc => filterValues.categories.includes(pc.id))
      )
    }

    // filter child sectors
    if (filterValues.childSectors.length) {
      categoryItems = categoryItems.filter(p =>
          p.categories.some(pc => filterValues.childSectors.includes(pc.id))
      )
    }

    // filter brands
    if (filterValues.brands.length) {
      categoryItems = categoryItems.filter(p => {
        if (!p.product_brand) return false
        return p.product_brand
          .split(',')
          .some(br => filterValues.brands.includes(br.trim()))
      })
    }

    if (filterValues.ranges.length) {
      categoryItems = categoryItems.filter(p => {
        if (!p.range) return false
        return p.range
            .toString()
            .split(',')
            .some(br => filterValues.ranges.includes(br.trim()))
      })
    }

    // filter room type
    if (filterValues.rooms.length) {
      categoryItems = categoryItems.filter(p => {
        if (!p.room_type) return false
        return p.room_type
            .toString()
            .split(',')
            .some(br => filterValues.rooms.includes(br.trim()))
      })

      // console.log(categoryItems)
    }

    // filter ages
    if (filterValues.ages.length) {
      categoryItems = categoryItems.filter(p => {
        if (!p.ages) return false
        return p.ages
          .split(',')
          .some(br => filterValues.ages.includes(br.trim()))
      })
    }

    // filter price
    if (filterValues.minPrice) {
      const min = parseFloat(filterValues.minPrice)
      categoryItems = categoryItems.filter(p => p.salePrice >= min)
    }

    // filter price
    if (filterValues.maxPrice) {
      const max = parseFloat(filterValues.maxPrice)
      categoryItems = categoryItems.filter(p => p.salePrice <= max)
    }

    //filter Visibility
    if (filterValues.excVisibility.length) {
      categoryItems = categoryItems.filter(p => {
        if (!p.visibility_text) return false
        return !filterValues.excVisibility.includes(p.visibility_text)
      })
    }

    //filter Color
    if (filterValues.color?.value) {
      categoryItems = categoryItems.filter(p => {
        const pModel = new Product({...p}, priceRuleItems)
        if (pModel.isBundle) return false
        if (pModel.type === Product.SIMPLE) {
          return pModel.colour === filterValues.color.value
        }
        if (pModel.type === Product.CONFIGURABLE) {
          return pModel.variants.some(v => {
            return v.colour === filterValues.color.value
          })
        }
      })
    }

    if (category.name === 'Sale') {
      categoryItems = categoryItems.filter(p => {
        return p.categories.some(function (v) {
          let valid_sale_categories = sale_categories_name.indexOf(v.name) >= 0
          let has_sale_price = p.salePrice < p.price
          return valid_sale_categories || has_sale_price
        })
      })
    }
    let sortedItems = [
      ...categoryItems.sort((a, b) => {
        switch (filterValues.sort) {
          case 'az':
            return a.name.localeCompare(b.name)
          case 'za':
            return b.name.localeCompare(a.name)
          case 'lowhigh':
            return a.salePrice - b.salePrice
          case 'highlow':
            return b.salePrice - a.salePrice
        }
        return 0
      }),
    ]
    if (filterValues.limit != 'all') {
      sortedItems = sortedItems.slice(startIndex, endIndex)
    }
    setItems(sortedItems)
  }, [
    category,
    filterValues.limit,
    filterValues.sort,
    currentPage,
    products,
    filterValues.categories,
    filterValues.childSectors,
    filterValues.brands,
    filterValues.ranges,
    filterValues.rooms,
    filterValues.ages,
    filterValues.minPrice,
    filterValues.maxPrice,
    filterValues.color,
    priceRuleItems
  ])

  const pageInfo = useMemo(() => {
    let totalPage = 1

    if (category) {
      totalPage = Math.ceil(products.length / filterValues.limit)
    }

    return {
      currentPage,
      totalPage,
    }
  }, [category, currentPage, filterValues, products])

  const onFilterChange = useCallback((name, value) => {
    if (name === 'limit') setCurrentPage(1)
    setFilterValues(oldValues => {
      oldValues[name] = value
      return { ...oldValues }
    })
  }, [])

  const onFilterClear = useCallback(() => {
    setFilterValues({ ...defaultFilters })
  }, [])

  const showProducts = useMemo(
    () =>
      category.display_mode === 'PRODUCTS' ||
      category.display_mode === 'PRODUCTS_AND_PAGE',
    [category.display_mode]
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined'){
      if (!items || items.length === 0) return
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        ecommerce: {
          impressions: items.map((item, index) => {
            return {
              name: item.name,
              id: item.id,
              price: item.salePrice,
              position: index + 1,
            }
          }),
        },
      })
    }
  }, [category, items])

  function loadMore() {
    setVisible(visible + 36)
  }

  const excludeCategories = useStaticQuery(graphql`
    {
      magento {
        categories(pageSize: 300, filters: {ids: {in: ["168", "44", "62"]}}) {
          items {
            id,
            name
            children {
              id
              name
              children {
                id
                name
              }
            }
          }
        }
      }
    }
  `)


  var excCat = [];
  var childSectors = [];

  excludeCategories.magento.categories.items.forEach(function (item) {

    excCat.push(item.id)
    if (item.children){
      item.children.forEach(function (child) {
        if (item.name === 'Sectors'){
          childSectors.push(child)
        }

        excCat.push(child.id)
        if (child.children){
          child.children.forEach(function (child2) {
            excCat.push(child2.id)
          })
        }
      })
    }
  })

  /*Hide the categories from filter if it's disabled in Magento*/
  categories.children_data.forEach(function (item) {

    if (!item.is_active){
      excCat.push(item.id)
    }

    if (item.children_data){
      item.children_data.forEach(function (child) {

        if (!child.is_active){
          excCat.push(child.id)
        }
        if (child.children_data){
          child.children_data.forEach(function (child2) {
            if (!child2.is_active){
              excCat.push(child.id)
            }
          })
        }
      })
    }

  })

  /*Hide the child g8 categories if it's not g8 parent page*/
  if (category.url_path !== 'customers/g8'){
    excCat.push(132, 135);
  }

  const categoriesForFilter = useMemo(() => {
    let cats = []

    for (const product of products) {
      cats = cats.concat(product.categories)
    }

    const uniqueIds = new Set(cats.map(c => c.id).filter(c => !excCat.includes(c)))
    return Array.from(uniqueIds)
      .filter(cid => cid !== category.id)
      .map(cid => cats.find(c => c.id === cid))
  }, [category, products])

  return (
    <Layout>
      <Helmet>
        {!!category.meta_title && <title>{category.meta_title}</title>}
        {!!category.meta_title && <meta name="title" content={category.meta_title} />}
        {!!category.meta_description && <meta name="description" content={category.meta_description} />}
        {!!category.meta_keyword && <meta name="keywords" content={category.meta_keyword} />}
      </Helmet>

      {!!category?.image && <Banner title={category.name} imageURL={category.image} />}

      <BreadCrumbBlock
        items={category.breadcrumbs || []}
        current={{ path: category.url_path, name: category.name }}
      />

      <Section noSpacingTop hasDivider>
        <Title title={category.name} className="text-center" />
        <Container>
          <Filter
            childSectorsArr={childSectors}
            categories={categoriesForFilter}
            filterValues={filterValues}
            onChange={onFilterChange}
            onClear={onFilterClear}
          />

          <Cate
            category={category}
            items={items}
            pageInfo={pageInfo}
            visible={visible}
            onPaginationItemClick={onPaginationItemClick}
          />
          {visible < items.length && (
            <button
              className="btn btn-outline-primary mx-auto d-flex"
              onClick={loadMore}
            >
              Show More Products
            </button>
          )}
        </Container>
      </Section>
      {/*<Section>*/}
      {/*  <Tile />*/}
      {/*</Section>*/}
    </Layout>
  )
}

export default Categories
