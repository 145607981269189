import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentCategoryId: null
}

export const registrySlice = createSlice({
  name: 'registry',
  initialState,
  reducers: {
    setCurrentCategoryId: (state, action) => {
      state.currentCategoryId = action.payload
    },
  },
})

export const { setCurrentCategoryId } = registrySlice.actions

export default registrySlice.reducer
